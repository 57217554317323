@-ms-viewport {
  width: device-width; }

h1 {
  margin-bottom: 15px;
  font-size: 21px;
  font-size: 1.3125rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 800;
  letter-spacing: -0.0475em;
  line-height: 1; }
  @media (min-width: 576px) {
    h1 {
      font-size: 24px;
      font-size: 1.5rem; } }
  @media (min-width: 992px) {
    h1 {
      font-size: 36px;
      font-size: 2.25rem;
      letter-spacing: -0.06em; } }

/* h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	@include heading(30px 0 15px, $font-family-headings);
	text-transform: uppercase;
} */
.heading, h2, h3 {
  margin: 30px 0 15px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: -0.0475em;
  line-height: 1; }
  .heading small, h2 small, h3 small {
    display: block;
    font-size: 16px;
    font-size: 1rem;
    font-weight: normal; }

.heading_size, .h2, .h3 {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: -0.0475em;
  line-height: 1; }
  .heading_size small, .h2 small, .h3 small {
    display: block;
    font-size: 16px;
    font-size: 1rem;
    font-weight: normal; }

h2 {
  font-size: 27px;
  font-size: 1.6875rem;
  font-weight: 600; }
  @media (min-width: 768px) {
    h2 {
      font-size: 32px;
      font-size: 2rem; } }
  h2 + h3, h2 + .h3 {
    margin-top: -10px; }

.h2 {
  font-size: 27px;
  font-size: 1.6875rem;
  font-weight: 600; }
  @media (min-width: 768px) {
    .h2 {
      font-size: 32px;
      font-size: 2rem; } }
  .h2 + h3, .h2 + .h3 {
    margin-top: -10px; }

h3 {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 800; }
  @media (min-width: 768px) {
    h3 {
      font-size: 27px;
      font-size: 1.6875rem; } }
  h3 + h4, h3 + .h4 {
    margin-top: -10px; }

.h3 {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 800; }
  @media (min-width: 768px) {
    .h3 {
      font-size: 27px;
      font-size: 1.6875rem; } }
  .h3 + h4, .h3 + .h4 {
    margin-top: -10px; }

.service_front {
  padding: 15px 0; }
  .service_front .row {
    justify-content: center; }
  @media (max-width: 767.98px) {
    .service_front .container {
      max-width: none; }
    .service_front .service_content {
      max-width: 540px;
      margin: 0 auto; } }
  @media (min-width: 768px) {
    .service_front {
      background-color: white; } }

.service_item {
  padding: 30px;
  text-align: center; }
  @media (max-width: 767.98px) {
    .service_item:nth-child(odd) {
      background-color: rgba(255, 255, 255, 0.5); } }
  @media (min-width: 768px) {
    .service_item {
      flex: 0 0 50%;
      max-width: 50%; } }
  .service_item a {
    display: block;
    padding: 15px;
    background-size: 0;
    background-repeat: no-repeat;
    background-position: center center;
    transition: background-size 400ms;
    overflow: visible; }
    .service_item a:hover {
      background-size: 50%;
      transition: background-size 400ms; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .service_item a:hover {
        background-size: 75%; } }
  .service_item.water a {
    color: #1699d1;
    background-image: radial-gradient(circle at 50%, rgba(22, 153, 209, 0.2) 50%, transparent 0); }
  .service_item.water svg {
    fill: #1699d1; }
  .service_item.gas a {
    color: #cc3f35;
    background-image: radial-gradient(circle at 50%, rgba(204, 63, 53, 0.2) 50%, transparent 0); }
  .service_item.gas svg {
    fill: #cc3f35; }
  .service_item.pool a {
    color: #3f4991;
    background-image: radial-gradient(circle at 50%, rgba(63, 73, 145, 0.2) 50%, transparent 0); }
  .service_item.pool svg {
    fill: #3f4991; }
  .service_item.equipment a {
    color: #ff7d11;
    background-image: radial-gradient(circle at 50%, rgba(255, 125, 17, 0.2) 50%, transparent 0); }
  .service_item.equipment svg {
    fill: #ff7d11; }

.service_group {
  overflow: visible; }

.service_media {
  margin-bottom: 15px;
  overflow: visible; }

.service_icon {
  width: 75px;
  height: 75px;
  fill: #3585c6; }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .service_icon {
      width: 150px;
      height: 150px;
      padding: 0 15px; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .service_icon {
      width: 250px;
      height: 250px;
      padding: 0 15px; } }
  @media (min-width: 992px) {
    .service_icon {
      width: 100px;
      height: 100px; } }

.service-title {
  margin-top: 0; }

ul, ol {
  margin: 0 0 15px;
  padding: 0 0 0 18px; }

ul {
  list-style: square; }

li {
  margin: 0;
  padding: 0; }
  li > ul,
  li > ol {
    margin: 5px 0 5px 3px; }

.list-note {
  line-height: 1.2; }

.list-inline {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }
  .list-inline li {
    display: inline-block; }
    .list-inline li:not(:last-child) {
      margin: 0 8px 6px 0; }

.button {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 4px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-decoration: none;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.6); }
  .button:hover {
    cursor: pointer; }
  .button:hover {
    text-shadow: 0 0px 4px transparent; }
  .button.sm {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-decoration: none;
    font-size: 85%; }
    .button.sm:hover {
      cursor: pointer; }
  .button.lg {
    display: inline-block;
    padding: 15px 20px;
    border-radius: 4px;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-decoration: none;
    font-size: 115%; }
    .button.lg:hover {
      cursor: pointer; }
  .button.primary {
    background-color: #3585c6;
    color: white; }
    .button.primary:hover, .button.primary:focus {
      background-color: #e93d24;
      color: white; }
    @media (min-width: 768px) {
      .button.primary {
        transition: all 400ms;
        background-image: radial-gradient(circle at 50%, #e93d24 50%, transparent 0);
        background-size: 0;
        background-position: 50%;
        background-repeat: no-repeat; }
        .button.primary:hover, .button.primary:focus {
          transition: all 400ms;
          background-color: #e93d24;
          border-color: #f07b6a;
          background-size: 250%; } }
  .button.secondary {
    background-color: #e93d24;
    color: white; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #0073df;
      color: white; }
    @media (min-width: 768px) {
      .button.secondary {
        transition: all 400ms;
        background-image: radial-gradient(circle at 50%, #0073df 50%, transparent 0);
        background-size: 0;
        background-position: 50%;
        background-repeat: no-repeat; }
        .button.secondary:hover, .button.secondary:focus {
          transition: all 400ms;
          background-color: #0073df;
          border-color: #2d99ff;
          background-size: 250%; } }
  .button.highlight {
    background-color: #0f426b;
    color: white; }
    .button.highlight:hover, .button.highlight:focus {
      background-color: #0073df;
      color: white; }
    @media (min-width: 768px) {
      .button.highlight {
        transition: all 400ms;
        background-image: radial-gradient(circle at 50%, #0073df 50%, transparent 0);
        background-size: 0;
        background-position: 50%;
        background-repeat: no-repeat; }
        .button.highlight:hover, .button.highlight:focus {
          transition: all 400ms;
          background-color: #0073df;
          border-color: #2d99ff;
          background-size: 250%; } }
  .button.accent {
    background-color: #ffce2d;
    color: white; }
    .button.accent:hover, .button.accent:focus {
      background-color: #0073df;
      color: white; }
    @media (min-width: 768px) {
      .button.accent {
        transition: all 400ms;
        background-image: radial-gradient(circle at 50%, #0073df 50%, transparent 0);
        background-size: 0;
        background-position: 50%;
        background-repeat: no-repeat; }
        .button.accent:hover, .button.accent:focus {
          transition: all 400ms;
          background-color: #0073df;
          border-color: #2d99ff;
          background-size: 250%; } }
  .button.success {
    background-color: #23ab08;
    color: white; }
    .button.success:hover, .button.success:focus {
      background-color: #0073df;
      color: white; }
    @media (min-width: 768px) {
      .button.success {
        transition: all 400ms;
        background-image: radial-gradient(circle at 50%, #0073df 50%, transparent 0);
        background-size: 0;
        background-position: 50%;
        background-repeat: no-repeat; }
        .button.success:hover, .button.success:focus {
          transition: all 400ms;
          background-color: #0073df;
          border-color: #2d99ff;
          background-size: 250%; } }
  .button.danger {
    background-color: #e44944;
    color: white; }
    .button.danger:hover, .button.danger:focus {
      background-color: #0073df;
      color: white; }
    @media (min-width: 768px) {
      .button.danger {
        transition: all 400ms;
        background-image: radial-gradient(circle at 50%, #0073df 50%, transparent 0);
        background-size: 0;
        background-position: 50%;
        background-repeat: no-repeat; }
        .button.danger:hover, .button.danger:focus {
          transition: all 400ms;
          background-color: #0073df;
          border-color: #2d99ff;
          background-size: 250%; } }
  .button.warning {
    background-color: #edc63b;
    color: #222; }
    .button.warning:hover, .button.warning:focus {
      background-color: #0073df;
      color: white; }
    @media (min-width: 768px) {
      .button.warning {
        transition: all 400ms;
        background-image: radial-gradient(circle at 50%, #0073df 50%, transparent 0);
        background-size: 0;
        background-position: 50%;
        background-repeat: no-repeat; }
        .button.warning:hover, .button.warning:focus {
          transition: all 400ms;
          background-color: #0073df;
          border-color: #2d99ff;
          background-size: 250%; } }
  .button.info {
    background-color: #6610f2;
    color: white; }
    .button.info:hover, .button.info:focus {
      background-color: #0073df;
      color: white; }
    @media (min-width: 768px) {
      .button.info {
        transition: all 400ms;
        background-image: radial-gradient(circle at 50%, #0073df 50%, transparent 0);
        background-size: 0;
        background-position: 50%;
        background-repeat: no-repeat; }
        .button.info:hover, .button.info:focus {
          transition: all 400ms;
          background-color: #0073df;
          border-color: #2d99ff;
          background-size: 250%; } }
  .button.dark {
    background-color: #222;
    color: white; }
    .button.dark:hover, .button.dark:focus {
      background-color: #0073df;
      color: white; }
    @media (min-width: 768px) {
      .button.dark {
        transition: all 400ms;
        background-image: radial-gradient(circle at 50%, #0073df 50%, transparent 0);
        background-size: 0;
        background-position: 50%;
        background-repeat: no-repeat; }
        .button.dark:hover, .button.dark:focus {
          transition: all 400ms;
          background-color: #0073df;
          border-color: #2d99ff;
          background-size: 250%; } }
  .button.light {
    background-color: #f5f5f5;
    color: #222; }
    .button.light:hover, .button.light:focus {
      background-color: #0073df;
      color: white; }
    @media (min-width: 768px) {
      .button.light {
        transition: all 400ms;
        background-image: radial-gradient(circle at 50%, #0073df 50%, transparent 0);
        background-size: 0;
        background-position: 50%;
        background-repeat: no-repeat; }
        .button.light:hover, .button.light:focus {
          transition: all 400ms;
          background-color: #0073df;
          border-color: #2d99ff;
          background-size: 250%; } }

.resources_front {
  padding-top: 0; }

.resources-list li:not(.linked) {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 4px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-decoration: none;
  border: 1px solid #ccc; }
  .resources-list li:not(.linked):hover {
    cursor: pointer; }

.contractors_front {
  text-align: center;
  background-color: white; }
  .contractors_front h3 {
    margin-top: 0; }
  .contractors_front .wrap {
    max-width: 780px;
    margin: 0 auto; }

.contractors-list {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }
  .contractors-list a {
    display: block;
    padding: 8px 12px;
    border-radius: 4px;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-decoration: none;
    background-color: #3585c6;
    color: white;
    font-size: 18px;
    font-size: 1.125rem;
    font-stretch: 75%;
    font-weight: 590; }
    .contractors-list a:hover {
      cursor: pointer; }
    .contractors-list a:hover, .contractors-list a:focus {
      background-color: #e93d24;
      color: white; }
    @media (min-width: 768px) {
      .contractors-list a {
        transition: all 400ms;
        background-image: radial-gradient(circle at 50%, #e93d24 50%, transparent 0);
        background-size: 0;
        background-position: 50%;
        background-repeat: no-repeat; }
        .contractors-list a:hover, .contractors-list a:focus {
          transition: all 400ms;
          background-color: #e93d24;
          border-color: #f07b6a;
          background-size: 250%; } }
  .contractors-list > li {
    display: inline-block;
    margin: 0 10px 10px 0; }
    .contractors-list > li:last-child a {
      display: block;
      padding: 7px 12px;
      border-radius: 4px;
      font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
      text-decoration: none;
      background-color: transparent;
      border: 2px solid #e93d24;
      color: #e93d24; }
      .contractors-list > li:last-child a:hover {
        cursor: pointer; }
      .contractors-list > li:last-child a:hover, .contractors-list > li:last-child a:focus {
        background-color: #e93d24;
        color: white; }
      @media (min-width: 768px) {
        .contractors-list > li:last-child a {
          transition: all 400ms;
          transform: scale(0.99);
          background-color: transparent;
          background-image: radial-gradient(circle at 50%, #e93d24 50%, transparent 0);
          background-size: 0;
          background-position: 50%;
          background-repeat: no-repeat; }
          .contractors-list > li:last-child a:hover, .contractors-list > li:last-child a:focus {
            transition: all 400ms;
            transform: scale(1);
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
            background-color: transparent;
            background-size: 250%;
            color: white; } }

.register_block {
  padding: 30px;
  text-align: center; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .register_block {
      font-size: 14px;
      font-size: 0.875rem; }
      .register_block h4 {
        font-size: 18px;
        font-size: 1.125rem; } }
  @media (min-width: 768px) {
    .register_block {
      padding: 60px 15px;
      background-image: url("/_/images/page/front/register/left.png"), url("/_/images/page/front/register/right.png");
      background-position: left bottom, right bottom;
      background-repeat: no-repeat;
      background-size: contain; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .register_block {
      background-size: auto 100%, auto 100%; }
      .register_block .wrap p span {
        display: inline-block;
        padding: 10px;
        background-color: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(10px); } }
  @media (min-width: 1200px) {
    .register_block {
      padding: 90px 15px; } }

.register-button {
  font-size: 21px;
  font-size: 1.3125rem;
  font-weight: 700;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 4px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-decoration: none;
  background-color: #e44944;
  color: white; }
  .register-button:hover {
    cursor: pointer; }
  .register-button:hover, .register-button:focus {
    background-color: #2d99ff;
    color: white; }
  @media (min-width: 768px) {
    .register-button {
      transition: all 400ms;
      background-image: radial-gradient(circle at 50%, #2d99ff 50%, transparent 0);
      background-size: 0;
      background-position: 50%;
      background-repeat: no-repeat; }
      .register-button:hover, .register-button:focus {
        transition: all 400ms;
        background-color: #2d99ff;
        border-color: #79beff;
        background-size: 250%; } }

/*
@import "templates/front/gallery";
@import "templates/front/reviews";
@import "components/button/site-button";
@import "templates/page/empty-legs";
@import "templates/front/about";
 */
.info_block {
  padding: 30px 15px;
  line-height: 1.5786;
  text-align: center;
  background-color: #222;
  color: white; }

.info-list {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
  line-height: 1.3; }
  @media (min-width: 768px) {
    .info-list {
      margin-left: 0;
      padding-left: 0;
      list-style-type: none;
      list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }
      .info-list li {
        display: inline-block; }
        .info-list li:not(:last-child) {
          margin: 0 8px 6px 0; }
      .info-list > li {
        margin-right: 15px !important; } }

address {
  margin: 0 0 15px;
  font-style: normal;
  line-height: 1; }
  address .street {
    display: block; }

.hidden {
  display: none !important; }

.invisible {
  opacity: 0 !important; }

@media (max-width: 575.98px) {
  .hidden_xs {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .hidden_sm {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .hidden_md {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .hidden_lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden_xl {
    display: none !important; } }

[class*="visible_"] {
  display: none !important; }

@media (max-width: 575.98px) {
  .visible_xs {
    display: block !important; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .visible_sm {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .visible_md {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .visible_lg {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible_xl {
    display: block !important; } }

.fs100 {
  font-size: 100% !important; }

.fs115 {
  font-size: 115% !important; }

.fs125 {
  font-size: 125% !important; }

.txt_center {
  text-align: center; }

.lh1 {
  line-height: 1 !important; }

.lh125 {
  line-height: 1.25 !important; }

.mt0 {
  margin-top: 0 !important; }

.mt5 {
  margin-top: 5px !important; }

.mt10 {
  margin-top: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.mt25 {
  margin-top: 25px !important; }

.mt30 {
  margin-top: 30px !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.pt0 {
  padding-top: 0 !important; }

.pt5 {
  padding-top: 5px !important; }

.pt10 {
  padding-top: 10px !important; }

.pt15 {
  padding-top: 15px !important; }

.pt20 {
  padding-top: 20px !important; }

.pt25 {
  padding-top: 25px !important; }

.pt30 {
  padding-top: 30px !important; }

.pb0 {
  padding-bottom: 0 !important; }

.pb5 {
  padding-bottom: 5px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pb25 {
  padding-bottom: 25px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.color-text {
  color: #222; }

.color-red {
  color: #e44944; }

.bg_white {
  background-color: white; }
