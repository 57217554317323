@mixin btn($padding: 15px, $radius: 4px, $display: inline-block) {
	display: $display;
	padding: $padding;
	border-radius: $radius;
	font-family: $font-family-sans-serif;
	text-decoration: none;
	&:hover {
		cursor: pointer;
	}
}

	@mixin 
		btn-solid(
			$bg: $color-link, 
			$link: $white, 
			$bg-hover: $color-hover, 
			$hover: $color-text
		) 
	{
		background-color: $bg;
		color: $link;
		
		&:hover,
		&:focus {
			background-color: $bg-hover;
			color: $hover;
		}

		@include media-breakpoint-up(md) {
			transition: all 400ms;
			background-image: radial-gradient(
				circle at 50%,
				$bg-hover 50%,
				transparent 0
			);
			background-size: 0;
			background-position: 50%;
			background-repeat: no-repeat;
			&:hover,
			&:focus {
				transition: all 400ms;
				background-color: $bg-hover;
				border-color: lighten($bg-hover, 15%);
				background-size: 250%;
				//text-shadow: 0 1px 3px rgba(black,0.5);
			}
		}
	}

 	@mixin btn-ghost($bg: transparent, $to: $color-link, $text: $white) {
		background-color: $bg;
		border: 2px solid $to;
		color: $to;
		&:hover,
		&:focus {
			background-color: $to;
			color: $text;
		}
		@include media-breakpoint-up(md) {
			transition: all 400ms;
			transform: scale(0.99);
			background-color: $bg;
			background-image: radial-gradient(
				circle at 50%,
				$to 50%,
				transparent 0
			);
			background-size: 0;
			background-position: 50%;
			background-repeat: no-repeat;
			&:hover,
			&:focus {
				transition: all 400ms;
				transform: scale(1);
				text-shadow: 0 1px 2px rgba(black,0.5);
				background-color: $bg;
				background-size: 250%;
				color: $text;
			}

		}
	}