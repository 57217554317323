h1 { 
	margin-bottom: 15px;
	@include font-size(21);
	font-family: $font-family-headings;
	font-weight: $heading-weight-bolder;
	letter-spacing: -0.0475em;
	line-height: 1;

	@include media-breakpoint-up(sm) {
		@include font-size(24);
	}

	@include media-breakpoint-up(lg) {
		@include font-size(36);
		letter-spacing: -0.06em;
	}

}