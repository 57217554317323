.hidden { display: none !important; }
.invisible { opacity: 0 !important; }

.hidden_xs { @include media-breakpoint-down(xs) { display: none !important; } }
.hidden_sm { @include media-breakpoint-only(sm) { display: none !important; } }
.hidden_md { @include media-breakpoint-only(md) { display: none !important; } }
.hidden_lg { @include media-breakpoint-only(lg) { display: none !important; } }
.hidden_xl { @include media-breakpoint-up(xl) { display: none !important; } }

[class*="visible_"] { display: none !important; }
.visible_xs { @include media-breakpoint-down(xs) { display: block !important; } }
.visible_sm { @include media-breakpoint-only(sm) { display: block !important; } }
.visible_md { @include media-breakpoint-only(md) { display: block !important; } }
.visible_lg { @include media-breakpoint-only(lg) { display: block !important; } }
.visible_xl { @include media-breakpoint-up(xl) { display: block !important; } }

.fs100 { font-size: 100% !important; }
.fs115 { font-size: 115% !important; }
.fs125 { font-size: 125% !important; }

.txt_center { text-align: center; }
.lh1 { line-height: 1 !important;}
.lh125 { line-height: 1.25 !important;}

.mt0 { margin-top: 0 !important; }
.mt5 { margin-top: 5px !important; }
.mt10 { margin-top: 10px !important; }
.mt15 { margin-top: 15px !important; }
.mt20 { margin-top: 20px !important; }
.mt25 { margin-top: 25px !important; }
.mt30 { margin-top: 30px !important; }

.mb0 { margin-bottom: 0 !important; }
.mb5 { margin-bottom: 5px !important; }
.mb10 { margin-bottom: 10px !important; }
.mb15 { margin-bottom: 15px !important; }
.mb20 { margin-bottom: 20px !important; }
.mb25 { margin-bottom: 25px !important; }
.mb30 { margin-bottom: 30px !important; }

.pt0 { padding-top: 0 !important; }
.pt5 { padding-top: 5px !important; }
.pt10 { padding-top: 10px !important; }
.pt15 { padding-top: 15px !important; }
.pt20 { padding-top: 20px !important; }
.pt25 { padding-top: 25px !important; }
.pt30 { padding-top: 30px !important; }

.pb0 { padding-bottom: 0 !important; }
.pb5 { padding-bottom: 5px !important; }
.pb10 { padding-bottom: 10px !important; }
.pb15 { padding-bottom: 15px !important; }
.pb20 { padding-bottom: 20px !important; }
.pb25 { padding-bottom: 25px !important; }
.pb30 { padding-bottom: 30px !important; }


.color-text { color: $color-text; }
.color-red { color: $red; }

.bg_white {
    background-color: white;
}