.service {
	
	&_front {
		padding: 15px 0;		

		.row {
			justify-content: center;
		}

		@include media-breakpoint-down(sm) {
			.container {
				max-width: none;
			}
			.service_content {
				max-width: 540px;
				margin: 0 auto;
			}
		}

		@include media-breakpoint-up(md) {
			background-color: white;
		}

	}

	&_item {
		padding: 30px;
		text-align: center;

		@include media-breakpoint-down(sm) {
			&:nth-child(odd) {
				background-color: rgba(white,0.5);
			}
		}

		@include media-breakpoint-up(md) {
			@include make-col(1,2);
		}

		a {
			display: block;
			padding: 15px;
			background-size: 0;
			background-repeat: no-repeat;
			background-position: center center;
			transition: background-size 400ms;
			overflow: visible;
			&:hover {
				background-size: 50%;
				transition: background-size 400ms;
			}
			@include media-breakpoint-only(md) {
				&:hover { background-size: 75%; }
			}
		}

		&.water {
			a { 
				color: $color-water; 
				background-image: radial-gradient(
					circle at 50%,
					rgba($color-water,0.2) 50%,
					transparent 0
				);
			}
			svg { fill: $color-water; }
		}

		&.gas {
			a { 
				color: $color-gas; 
				background-image: radial-gradient(
					circle at 50%,
					rgba($color-gas,0.2) 50%,
					transparent 0
				);
			}
			svg { fill: $color-gas; }
		}

		&.pool {
			a { 
				color: $color-pool; 
				background-image: radial-gradient(
					circle at 50%,
					rgba($color-pool,0.2) 50%,
					transparent 0
				);
			}
			svg { fill: $color-pool; }
		}

		&.equipment {
			a { 
				color: $color-equipment; 
				background-image: radial-gradient(
					circle at 50%,
					rgba($color-equipment,0.2) 50%,
					transparent 0
				);
			}
			svg { fill: $color-equipment; }
		}

	}

	&_group {
		overflow: visible;
	}

	&_media {
		margin-bottom: 15px;
		overflow: visible;
	}

	&_icon {
		width: 75px;
		height: 75px;
		fill: $brand-primary;

		@include media-breakpoint-only(sm) {
			width: 150px;
			height: 150px;
			padding: 0 15px;
		}

		@include media-breakpoint-only(md) {
			width: 250px;
			height: 250px;
			padding: 0 15px;
		}

		@include media-breakpoint-up(lg) {
			width: 100px;
			height: 100px;
		}

	}

	&-title {
		margin-top: 0;
	}

}


