
.info {

	&_block {
		padding: 30px 15px;
		line-height: $base-line-height;
		text-align: center;
		background-color: $color-text;
		color: white;

	}

	&-list {
		@include list-unstyled;
		line-height: 1.3;
		@include media-breakpoint-up(md) {
			@include list-inline;
			> li {
				margin-right: 15px !important;
			}
		}
	}

}