h3 {
	@extend .heading;
	@include heading($h3, $h3wgt, $h3md); 
	+h4, +.h4 {
		margin-top: -10px;
	}
}

.h3 {
	@extend .heading_size;
	@include heading($h3, $h3wgt, $h3md); 
	+h4, +.h4 {
		margin-top: -10px;
	}
}