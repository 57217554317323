.register {

    &_block {
        padding: 30px;
        text-align: center;

        @include media-breakpoint-only(md) {
            @include font-size(14);
            h4 { @include font-size(18); }
        }

        @include media-breakpoint-up(md) {
            padding: 60px 15px; 
            background-image:   url("/_/images/page/front/register/left.png"),
                                url("/_/images/page/front/register/right.png");
            background-position: left bottom, right bottom;
            background-repeat: no-repeat;
            background-size: contain;        
        }

        @include media-breakpoint-only(md) {
            background-size: auto 100%, auto 100%;
            .wrap p span {
                display: inline-block;
                padding: 10px;
                background-color: rgba(white,0.7);
                backdrop-filter: blur(10px);
            }
        }

        @include media-breakpoint-up(xl) {
            padding: 90px 15px;
        }

    }

    &-button {
        @include font-size(21);
        font-weight: $body-weight-bolder;
        @include btn(10px 15px, 4px, inline-block);
        @include btn-solid(
            $red, white,
            $color-hover, white
        );
    }

}