.resources {
    
    &_front {
        padding-top: 0;
    }

    &-list {
        li:not(.linked) {
            @include btn(10px 15px, 4px, inline-block);
            border: 1px solid #ccc;
        }
    }

}