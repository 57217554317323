.contractors {
    &_front {
	    text-align: center;
	    background-color: white;
	    h3 { margin-top: 0; }
        .wrap {
            max-width: 780px;
            margin: 0 auto;
        }
    }
    &-list {
        @include list-unstyled;

        a {
            @include btn(8px 12px, 4px, block);
            @include btn-solid($brand-primary, white, $brand-secondary, white);
            @include font-size(18);
            font-stretch: 75%;
            font-weight: $body-weight-bold;
        }

        > li {
            display: inline-block;
            margin: 0 10px 10px 0;
            &:last-child {
                a {
                    @include btn(7px 12px, 4px, block);
                    @include btn-ghost(
                        transparent,
                        $brand-secondary,
                        white
                    );
                }
            }
        }

    }
}
