.button {

    // btn($padding: 15px, $radius: 4px, $display: inline-block)

    @include btn(10px 15px, 4px, inline-block);
	font-family: $font-family-sans-serif;
    font-weight: $body-weight-bolder;
    text-shadow: 0 1px 0 rgba(black,0.6);

    &:hover {
        text-shadow: 0 0px 4px transparent;
    }
    
    &.sm {
        @include btn(5px 10px, 4px, inline-block);
        font-size: 85%;
    }
    
    &.lg {
        @include btn(15px 20px, 4px, inline-block);
        font-size: 115%;
    }

    &.primary {
        @include btn-solid($brand-primary, white, $brand-secondary, white);
    }

    &.secondary {
        @include btn-solid($brand-secondary, white, $color-link, white);
    }

    &.highlight {
        @include btn-solid($brand-highlight, white, $color-link, white);
    }

    &.accent {
        @include btn-solid($brand-accent, white, $color-link, white);
    }

    &.success {
        @include btn-solid($color-success, white, $color-link, white);
    }

    &.danger {
        @include btn-solid($color-danger, white, $color-link, white);
    }

    &.warning {
        @include btn-solid($color-warning, $color-text, $color-link, white);
    }

    &.info {
        @include btn-solid($color-info, white, $color-link, white);
    }

    &.dark {
        @include btn-solid($color-text, white, $color-link, white);
    }

    &.light {
        @include btn-solid($white, $color-text, $color-link, white);
    }

}

