h2 {
	@extend .heading;
	@include heading($h2, $h2wgt, $h2md); 
	+ h3, +.h3 {
		margin-top: -10px;
	}
}

.h2 {
	@extend .heading_size;
	@include heading($h2, $h2wgt, $h2md); 
	+ h3, +.h3 {
		margin-top: -10px;
	}
}