/* h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	@include heading(30px 0 15px, $font-family-headings);
	text-transform: uppercase;
} */

.heading {
	margin: 30px 0 15px;
	font-family: $font-family-headings;
	letter-spacing: -0.0475em;
	line-height: 1;
	small {
		display: block;
		@include font-size($font-size-base);
		font-weight: normal;
	}
}

.heading_size {
	font-family: $font-family-headings;
	letter-spacing: -0.0475em;
	line-height: 1;
	small {
		display: block;
		@include font-size($font-size-base);
		font-weight: normal;
	}
}

$h1-f: 			32;
$h1-f-md:		42;
$h1-f-wgt:	 	800;

$h1: 			32;
$h1md:			42;
$h1wgt:		 	800;

$h2: 			27;
$h2md:			32;
$h2wgt:			600;

$h3: 			24;
$h3md:			27;
$h3wgt:			800;

$h4: 			21;
$h4md:			23;
$h4wgt:			600;

$h5: 			19;
$h5md:			19;
$h5wgt:			600;

$h6: 			18;
$h6md:			18;
$h6wgt:			400;








